import React from 'react';
import { Router,Route } from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';
import history from './../containers/History';

import Home from 		'./../pages/Home';
import Work from 		'./../pages/Work';
import Projects from 	'./../pages/Projects';
import Experience from 	'./../pages/Experience';
import Blog from 		'./../pages/Blog';
import Contact from 	'./../pages/Contact';
import Error from 		'./../pages/Error';

import Detail from 		'./../components/Detail';
import Menu from 		'./../containers/Menu';

import { initDraw, initCircle, initCursor } from './../helpers/Cursor';

import './../style/index.scss';

//import './../helpers/Shaders';
//import Graphql from 	'./../pages/Graphql';
//import 'whatwg-fetch'; 



class App extends React.Component {

	constructor(props){
		super(props);

		this.state = {detail: null}

		this.detailHandler = this.detailHandler.bind(this);

		
	}

	componentDidMount(){
		initCursor();
		initCircle();
		initDraw();
	}

	useEffect() {   
		this.props.history.replace(this.props.refreshRoute);
	}

	detailHandler(data){
		this.setState({detail: data});

		( data === null ) 
			? document.body.classList.remove('detail-open')
			: document.body.classList.add('detail-open');
	}

	render() {
    	return (
			<React.Fragment>

				<div className="loader"><span></span></div>

				<Router 	basename={process.env.PUBLIC_URL} 
							history={history} 
							detailHandler={this.detailHandler} 
				>

					<div id="content" className="container">

						<div id="main">

							<Menu />

							<AnimatedSwitch
							
								atEnter={{ opacity: 0 }}
								atLeave={{ opacity: 0 }}
								atActive={{ opacity: 1 }}
								className="switch-wrapper"
							>
								<Route exact path={`${process.env.PUBLIC_URL}/`} 			component={Home} />
								<Route exact path={`${process.env.PUBLIC_URL}/work`} 		render={(props) => <Work 		detailHandler={this.detailHandler} /> } />
								<Route exact path={`${process.env.PUBLIC_URL}/projects`} 	render={(props) => <Projects 	detailHandler={this.detailHandler} /> } />
								<Route exact path={`${process.env.PUBLIC_URL}/experience`} 	render={(props) => <Experience 	detailHandler={this.detailHandler} /> } />
								<Route exact path={`${process.env.PUBLIC_URL}/blog`} 		render={(props) => <Blog 		detailHandler={this.detailHandler} /> } />
								<Route exact path={`${process.env.PUBLIC_URL}/contact`} 	component={Contact} />
								{/*<Route exact path={`${process.env.PUBLIC_URL}/graphql`} 	component={Graphql} />*/}
								<Route 														component={Error} />

							</AnimatedSwitch>

							<Detail data={this.state.detail} detailHandler={this.detailHandler} />

						</div>

						<canvas className="cursor cursor--canvas" resize="true"></canvas>
						<div className="cursor cursor--small"></div>

					</div>

				</Router>

				<canvas className="lines" resize="true"></canvas>

			</React.Fragment>
    	)
	}
	
}

export default App;
