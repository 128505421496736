import React from 'react';
import { api } from '../data/Api';
import BlogArticle from '../components/BlogArticle';
import Empty from '../components/Empty';
//import { checkScroller } from '../containers/App';
import { toggleItems, setLoader, setAnimation, toggleMobileMenu, toTop } from '../helpers/General';
import { updateStyle, updateEvents } from './../helpers/Cursor';


class Blog extends React.Component{

	constructor(props){
		super(props);

		this.state = {
			data: null
		}
	}

	componentDidMount() {

		toggleMobileMenu();

		window.location.hash.replace('#','')

		setLoader(true);
		fetch( api.routes.blog )
			.then(response => response.json())
			.then(data => {
				setAnimation(false);
				this.setState({ data })
				toggleItems('.blog');

				updateEvents();
				updateStyle( "blog" );
				setLoader(false);

				toTop();
			});
	}

	render(){

		let entry = '';
		let list = [];
		let pages = [];
		let activeSlug = '';
		let urlSlug = window.location.hash.replace('#','').trim();

		if( this.state.data != null ){
			let items = this.state.data[0].category.items;
			// if items
			if( items.length > 0 ){
				// find item
				items.map( (item, index) => {
					// select by slug
					if( urlSlug !== '' && item.slug.trim() === urlSlug ){
						activeSlug = item.slug;
						entry = <BlogArticle key={item.slug} data={item} contentType="item"></BlogArticle>
					}
					// no slug, first in list
					if( urlSlug === '' && index === 0 ){
						activeSlug = item.slug;
						entry = <BlogArticle key={item.slug} data={item} contentType="item"></BlogArticle>
					}
					// didnt find any items by slug
					if( urlSlug !== '' && activeSlug === '' ){
						entry = <Empty message={`Strange url.`} />
					}

					return false;
				})
				// get list
				list = items.map( item => {
					let linkClass = ( item.slug === activeSlug ) ? 'active' : '';
					return <a key={item.slug} href={`blog#${item.slug}`} className={`${linkClass} blog-list__item`}>
								{/*<span className="blog-list__item-date">{parseDate(item.date_content)}</span> */}
								<span className="blog-list__item-text">{item.title}</span>
							</a>
				})
				// get pages
				pages = items.map( item => {
					let linkClass = ( item.slug === activeSlug ) ? 'active' : '';
					return <a key={item.slug} href={`blog#${item.slug}`} className={`${linkClass} blog-pages__item`}>
								{/*<span className="blog-pages__item-date">{parseDate(item.date_content)}</span> */}
								<span className="blog-pages__item-text">{item.title}</span>
							</a>
				})
			}else{
				entry = <Empty message={`No posts at this moment, come by later.`} />
				list = <Empty message={``} />
			}
		}

		return (
			<div className="blog">
				<div className="wrapper clearfix">

					<div className="blog-articles clearfix">

						<div className="blog-list">{ list }</div>

						{ entry }

						<div className="blog-pages">
							<p className="blog-pages__title">Posts</p>
							{ pages }
						</div>
					</div>
					
				</div>
			</div>
		)
	}
}

export default Blog;