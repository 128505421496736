import React from 'react';
import VizSensor from 'react-visibility-sensor';

class Img extends React.Component {

	constructor(props){
		super(props);

		this.state = ({mounted: false});

		this.handleVisible = this.handleVisible.bind(this);
	}

	componentDidMount() {
		/*
		setTimeout(()=>{
			this.setState({mounted: true});
		}, (this.props.index * 250) + 1000);
		*/
	}

	handleVisible(state) {
		if( state === true ){
			this.setState({mounted: true});
		}
	}

	render() {

		let imgClass = (this.state.mounted) ? 'mounted' : '';

		return (
			<VizSensor 	onChange={(isVisible) => { this.handleVisible(isVisible) }} 
						partialVisibility="bottom"
						intervalDelay="100"
						offset={{bottom: 0}}
			>
				<figure className={`figure ${imgClass}`}>
					<div className="figure__overlay"></div>
					<img className="figure__image" loading="lazy" src={this.props.src} alt={this.props.alt} style={{"min-height": "10px"}} />
					<figcaption className="figure__caption">{this.props.caption}</figcaption>
				</figure>
			</VizSensor>
		)
	}

}

export default Img;