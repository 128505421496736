import React from 'react';
import { api } from '../data/Api';
import Article from '../components/Article';
import Empty from '../components/Empty';
import { toggleItems, setLoader, setAnimation,toTop, toggleMobileMenu } from '../helpers/General';
import { updateStyle, updateEvents } from './../helpers/Cursor';
import 'whatwg-fetch'; 

class Work extends React.Component{

	constructor(props){
		super(props);

		this.state = {
			data: null
		}
	}

	componentDidMount() {

		toggleMobileMenu();

		setLoader(true);
		setAnimation(true);
		fetch( api.routes.work )
			.then(response => response.json())
			.then(data => {
				this.setState({ data })
				toggleItems('.work');

				updateEvents();
				updateStyle( "work" );
				setLoader(false);
				setAnimation(false);

				toTop();
			});
		
	}

	render(){

		let works = [];

		if( this.state.data != null ){

			let items = this.state.data[0].category.items;

			if( items.length > 0 ){
				works = items.map( (item,index) => {
					return <Article 	key={index} 
										index={index}
										count={items.length} 
										data={item} 
										contentType="item" 
										detailHandler={this.props.detailHandler}
							></Article>
				})
			}else{
				works = <Empty message={`Gathering work, please come back later.`} />
			}
		}

		return (
			<div className="work">
				<div className="wrapper">

					<div className="articles clearfix">
						{works}
					</div>
					
				</div>
			</div>
		)
	}
}

export default Work;