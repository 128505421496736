import React from 'react';
import { chunk } from './../helpers/General';

class Columnizer extends React.Component {

	render() {

		let columns = parseInt( this.props.columns );
		let items = this.props.items;
		let itemsColumnized = chunk(items, columns);

		let itemsCol = itemsColumnized.map( (columnItems,index) => {
			return <div key={index} className={`column col-${columns}`}>
				{
					columnItems.map( item =>{
						return item
					})
				}
			</div>
		});

		return (
			<React.Fragment>
				{itemsCol}
			</React.Fragment>
		)
	}

}

export default Columnizer;