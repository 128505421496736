import React from 'react';
import { updateStyle, updateEvents } from './../helpers/Cursor';
import { setAnimation,toTop, toggleMobileMenu } from '../helpers/General';
//import { api } from '../data/Api';

const text = [
	" work",
	" make",
	" develop",
	" create"
];

class Contact extends React.Component{

	contactInterval = 0;
	
	constructor(props){
		super(props);

		this.state = {
			prefix: 'let\'s ',
			words: '',
		}
	}

	componentDidMount() {

		toggleMobileMenu();

		setAnimation(false);
		
		this.renderText();
		this.contactInterval = setInterval( e => {
			this.renderText();
		},3700);

		updateEvents();
		updateStyle( "contact" );

		setAnimation(true);

		toTop();

		/*
		fetch( api.routes.settings )
			.then(response => response.json())
			.then(data => {
				this.setState({
					prefix: data[0].contact_prefix,
					words: data[0].contact_words
				})
			});
		*/
	}

	componentWillUnmount() {
		clearInterval( this.contactInterval );
	}

	renderText() {

		let contentContainer = document.querySelector('.js-contact-content');
		if( contentContainer === null ){
			return false;
		}
		contentContainer.innerHTML = '';

		//let arrLines = this.state.words.split(',');
		//let textLine = arrLines[arrLines.length * Math.random() | 0];
		let textLine = text[text.length * Math.random() | 0];
		let arrText = [...textLine ];
		let cnt = 0;
		for( let char in arrText ){
			let spanElem = document.createElement('span');
			spanElem.classList.add('contact__letter');
			let spanChar = ( arrText[char].trim() ) || '&nbsp;';
			if( spanChar === '&nbsp;' ){
				spanElem.classList.add('contact__letter--break');
			}
			spanElem.innerHTML = spanChar;
			contentContainer.appendChild(spanElem);
			cnt ++;
			setTimeout((e)=>{
				spanElem.classList.add('active');
			}, cnt * 53);
		}
	}

	render(){

		return (
			<div className="contact">
				<div className="wrapper">
					
						<p className="contact__content">
							<span className="contact__prefix">{this.state.prefix}</span>
							<span className="js-contact-content"></span>
							<span className="contact__suffix">.</span>
						</p>
						<p className="contact__links">
							<a href="mailto:boris.vidosevic@gmail.com">EM.</a>
							<a href="http://www.twitter.com/friglob" target="_blank" rel="noopener noreferrer">TW.</a>
							<a href="http://www.behance.com/friglob" target="_blank" rel="noopener noreferrer">BE.</a>
							<a href="http://www.instagram.com/friglob" target="_blank" rel="noopener noreferrer">IG.</a>
						</p>
				</div>
				
			</div>
		)
	}
}

export default Contact;