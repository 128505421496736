export const api = {

	routes: {
		settings: 		'https://www.borisvidosevic.com/cms/api/settings',
		menu: 			'https://www.borisvidosevic.com/cms/api/categories',
		work: 			'https://www.borisvidosevic.com/cms/api/categories/2?items=1&media=1&data=1',
		projects: 		'https://www.borisvidosevic.com/cms/api/categories/1?items=1&media=1&data=1',
		experience: 	'https://www.borisvidosevic.com/cms/api/categories/4?items=1&media=1&data=1',
		blog:  			'https://www.borisvidosevic.com/cms/api/categories/3?items=1&media=1&data=1',
	},

	drawings:
	{
		get: 			'https://www.borisvidosevic.com/drawings/get.php',
		set: 			'https://www.borisvidosevic.com/drawings/set.php',
		reset: 			'https://www.borisvidosevic.com/drawings/reset.php'
	},

	graphql: 			{
		url: 			'https://fakeql.com/graphql/0f839ce9ff2e123cbfef79055cb1b754',
		query: 			`{
							posts {
								id
								title
								date
							}
						}`
	}
}