import React from 'react';
import { updateStyle, updateEvents } from './../helpers/Cursor';

class Error extends React.Component{

	componentDidMount() {
		updateEvents();
		updateStyle( "contact" );
	}

	render(){

		return (
			<div className="error">
				<div className="wrapper">
					<h1>Hmmm</h1>
					<p>Can't find this page.</p>
				</div>
			</div>
		)
	}
}

export default Error;