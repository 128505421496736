//https://tympanus.net/codrops/2019/01/31/custom-cursor-effects/

import paper from 'paper';
//import SimplexNoise from 'simplex-noise';

import { api } from '../data/Api';

let clientX = -100;
let clientY = -100;

let lastX = 0;
let lastY = 0;
let isStuck = false;
let group, stuckX, stuckY;

let canvasCursor;
let polygon;

let ctxL;
let canvasLines;

let dpr = window.devicePixelRatio || 1;

let innerCursor;

let linesBezierCurve = 2;
let linesInterval = 100;
let linesGetInterval = 1173;
let linesResetInterval = 29999;
let linesStroke = '';
const strokeWidth = 5;
const linesStrokeWidth = 5;
const linesCap = "round"; // butt, round, square

let is_mouse_down = false;
let do_draw = true;

export const initCursor = () => {


	document.addEventListener("mousemove", e => {
		clientX = e.clientX;
		clientY = e.clientY;
	});

	// transform the innerCursor to the current mouse position
	// use requestAnimationFrame() for smooth performance
	const render = () => {

		innerCursor = document.querySelector(".cursor--small");
		if( innerCursor !== null ){
			innerCursor.style.transform = `translate(${clientX}px, ${clientY}px)`;
		}
	
		requestAnimationFrame(render);
	};

	requestAnimationFrame(render);
}

export const initCircle = () => {

	canvasCursor = document.querySelector(".cursor--canvas");
	const shapeBounds = {
		width: 100,
		height: 100
	};

	if( canvasCursor !== null ){
		const rect = canvasCursor.getBoundingClientRect();
		canvasCursor.width = rect.width * dpr;
		canvasCursor.height = rect.height * dpr;
		paper.setup(canvasCursor);
	}
	
	const segments = 20;
	const radius = 20;

	// we'll need these later for the noisy circle
	//const noiseScale = 10; // speed
	//const noiseRange = 0; // range of distortion
	let isNoisy = false; // state

	// the base shape for the noisy circle
	polygon = new paper.Path.RegularPolygon(
		new paper.Point(0, 0),
		segments,
		radius
	);
	polygon.strokeColor = "rgba(255,255,255,1)";
	polygon.strokeWidth = strokeWidth;
	polygon.smooth();
	group = new paper.Group([polygon]);
	group.applyMatrix = false;

	//const noiseObjects = polygon.segments.map(() => new SimplexNoise());
	let bigCoordinates = [];

	// function for linear interpolation of values
	const lerp = (a, b, n) => {
		return (1 - n) * a + n * b;
	};

	// function to map a value from one range to another range
	/*
	const map = (value, in_min, in_max, out_min, out_max) => {
		return (
		((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
		);
	};
	*/
	paper.view.onFrame = event => {
		if (!isStuck) {
			// move circle around normally
			lastX = lerp(lastX, clientX, 0.2);
			lastY = lerp(lastY, clientY, 0.2);
			group.position = new paper.Point(lastX, lastY);
		} else if (isStuck) {
			// fixed position on a nav item
			lastX = lerp(lastX, stuckX, 0.2);
			lastY = lerp(lastY, stuckY, 0.2);
			group.position = new paper.Point(lastX, lastY);
		}
		
		if (isStuck && polygon.bounds.width < shapeBounds.width) { 
			// scale up the shape 
			polygon.scale(1.05);
		} else if (!isStuck && polygon.bounds.width > 30) {
			// remove noise
			if (isNoisy) {
				polygon.segments.forEach((segment, i) => {
				segment.point.set(bigCoordinates[i][0], bigCoordinates[i][1]);
				});
				isNoisy = false;
				bigCoordinates = [];
			}
			// scale down the shape
			const scaleDown = .99;
			polygon.scale(scaleDown);
		}
		
		// while stuck and big, apply simplex noise
		/*
		if (isStuck && polygon.bounds.width >= shapeBounds.width) {
			isNoisy = false;
			// first get coordinates of large circle
			if (bigCoordinates.length === 0) {
				polygon.segments.forEach((segment, i) => {
				bigCoordinates[i] = [segment.point.x, segment.point.y];
				});
			}
			
			// loop over all points of the polygon
			polygon.segments.forEach((segment, i) => {
				
				// get new noise value
				// we divide event.count by noiseScale to get a very smooth value
				const noiseX = noiseObjects[i].noise2D(event.count / noiseScale, 0);
				const noiseY = noiseObjects[i].noise2D(event.count / noiseScale, 1);
				
				// map the noise value to our defined range
				const distortionX = map(noiseX, -1, 1, -noiseRange, noiseRange);
				const distortionY = map(noiseY, -1, 1, -noiseRange, noiseRange);
				
				// apply distortion to coordinates
				const newX = bigCoordinates[i][0] + distortionX;
				const newY = bigCoordinates[i][1] + distortionY; 
				
				// set new (noisy) coodrindate of point
				segment.point.set(newX, newY);
			});
		}
		*/
	
		polygon.smooth();
	};
}

export const initDraw = () => {

	canvasLines = document.querySelector(".lines");
	const rect = canvasLines.getBoundingClientRect();
	canvasLines.width = rect.width * dpr;
	canvasLines.height = rect.height * dpr;
	ctxL = canvasLines.getContext("2d");
	let newX = 0;
	let newY = 0;
	let prevX = 0;
	let prevY = 0;
	let tmstmp = [];

	const doDraw = () => {

		if( !do_draw ){
			return false;
		}

		newX = clientX * dpr;
		newY = clientY * dpr;

		const curveDiff = linesBezierCurve * dpr;
		if( prevX !== 0 && prevY !== 0 && prevX !== newX && prevY !== newY && is_mouse_down ){
			
			ctxL.moveTo(prevX, prevY);
			ctxL.lineCap = linesCap;
			ctxL.lineColor = "rgba(255,255,255,1)";
			ctxL.lineWidth = linesStrokeWidth;
			ctxL.beginPath();
			let rndX = (Math.ceil( Math.random())) ?  Math.random() * curveDiff : -Math.random() * curveDiff;
			let rndY = (Math.ceil( Math.random())) ?  Math.random() * curveDiff : -Math.random() * curveDiff;
			ctxL.bezierCurveTo(prevX, prevY, prevX + rndX, prevY + rndY, newX, newY);
			ctxL.stroke();

			// save with original dpr
			let date = new Date();
			setDraw( JSON.stringify([	parseInt(prevX/dpr), 
										parseInt(prevY/dpr), 
										parseInt(prevX/dpr + rndX), 
										parseInt(prevY/dpr + rndY), 
										parseInt(newX/dpr), 
										parseInt(newY/dpr), 
										linesStroke, 
										date.getTime() 
									]) );
			tmstmp.push( date.getTime() );
		}

		prevX = newX;
		prevY = newY;
		
	}
	const setDraw = (point) => {
		fetch( api.drawings.set + '?p=' + point );
			/*.then( response => console.log(response.status) );*/
	}
	const resetDraw = (point) => {
		fetch( api.drawings.reset );
			/*.then( response => console.log(response.status) );*/
	}
	const getDraw = () => {
		if( !do_draw ){
			return false;
		}
		let date = new Date();
		fetch( api.drawings.get )
			.then( response => { return response.json() })
			.then( data => {
				data.map( item => {
					// if not allready drawn and if newer than refresh tm && if newer than reset interval
					if( tmstmp.includes(item[7]) === false && (date.getTime() - item[7]) < linesResetInterval ){
						// curve
						ctxL.beginPath();
						ctxL.strokeStyle = item[6];
						ctxL.fillStyle = item[6];
						ctxL.lineColor = "rgba(255,255,255,1)";
						ctxL.lineWidth = linesStrokeWidth;
						ctxL.lineCap = linesCap;
						ctxL.moveTo(item[0]*dpr, item[1]*dpr);
						ctxL.bezierCurveTo(item[0]*dpr, item[1]*dpr, item[2]*dpr, item[3]*dpr, item[4]*dpr, item[5]*dpr);
						ctxL.stroke();
						tmstmp.push( item[7] );

						// start point
						/*ctxL.beginPath();
						ctxL.arc(item[0]*dpr, item[1]*dpr, 2*dpr, 0, 2 * Math.PI);
						ctxL.fill();
						ctxL.stroke();
						*/

						// end point
						/*ctxL.beginPath();
						ctxL.arc(item[4]*dpr, item[5]*dpr, 2*dpr, 0, 2 * Math.PI);
						ctxL.fill();
						ctxL.stroke();*/
					}
					return false;
				})
			})
			.catch( err => { console.log(err) });
	}

	const handleMouseDown = (e) => {
		if( !do_draw ){
			return false;
		}
		if( ctxL !== undefined 
			&& e.target.getAttribute('href') === null 
			&& e.target.nodeName.toLowerCase() !== 'button'
		){
			ctxL.beginPath();
			ctxL.arc(clientX*dpr, clientY*dpr, 2*dpr, 0, 2 * Math.PI);
			ctxL.fill();
			ctxL.stroke();
		}
	}

	const handleMouseUp = (e) => {
		// draw if...
		if( e.target.getAttribute('href') === null 
			&& e.target.nodeName.toLowerCase() !== 'button' ){
			is_mouse_down = !is_mouse_down;
		}
	}

	document.addEventListener("mousedown", handleMouseDown, true );
	document.addEventListener("mouseup", handleMouseUp, true );

	let page = document.querySelector('#content').dataset.page;
	updateStyle(page);

	getDraw();
	setTimeout(e=>{
		setInterval( doDraw, linesInterval );
		setInterval( getDraw, linesGetInterval );
		setInterval( resetDraw, linesResetInterval );
	},1000);
	
}

export const updateStyle = (page) => {

	let color = "247,247,247";
	let alpha = 0;

	do_draw = ( page === "home" || page === "contact" ) ? true : false;

	if( page === "home" ){
		color =  "247,247,247";
		alpha = 1;
	}
	if( page === "projects" || page === "work" || page === "blog"){
		color =  "68,149,149";
		alpha = 1;
	}
	if( page === "contact" || page === "experience" ){
		color =  "13,13,13";
		alpha = 1;
	}

	if( page === "detail" ){
		color =  "247,247,247";
		alpha = 1;
	}
	if( page === "menu" ){
		color =  "247,247,247";
		alpha = 1;
	}
	
	let alphaDpr = alpha * dpr;

	// inner cursor
	let innerCursor = document.querySelector(".cursor--small");
	if( innerCursor !== null ){
		innerCursor.style.background = `rgb(${color})`;
	}

	// lines color
	if( ctxL !== undefined ){
		ctxL.fillStyle =  `rgba(${color},1)`;
		ctxL.strokeStyle =  `rgba(${color},${alphaDpr})`;
		// save alpha in non-dpr
		linesStroke = `rgba(${color},${alpha})`;
	}
	
	// cursor bigger color
	if( polygon !== undefined ){
		polygon.strokeColor = `rgb(${color})`;
	}

}

const handleMouseEnter = e => {
	const item = e.currentTarget;
	const itemBox = item.getBoundingClientRect();
	stuckX = Math.round(itemBox.left + itemBox.width / 2);
	stuckY = Math.round(itemBox.top + itemBox.height / 2);
	isStuck = true;
};

// reset isStuck on mouseLeave
const handleMouseLeave = () => {
	isStuck = false;
};

export const updateEvents = () => {
	// add event listeners to all items
	const linkItems = document.querySelectorAll('a[href]');
	linkItems.forEach(item => {
		item.addEventListener("mouseenter", handleMouseEnter );
		item.addEventListener("mouseleave", handleMouseLeave );
	});
	const buttons = document.querySelectorAll('button');
	buttons.forEach(item => {
		item.addEventListener("mouseenter", handleMouseEnter );
		item.addEventListener("mouseleave", handleMouseLeave );
	});
}