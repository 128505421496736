import React from 'react';
import Img from './../components/Img';
//import { setLoader } from '../helpers/General';
import { updateStyle, updateEvents } from './../helpers/Cursor';

class Detail extends React.Component {

	constructor(props){
		super(props);

		this.closeItem = this.closeItem.bind(this);
		this.texturize = this.texturize.bind(this);
	}

	componentDidMount(){

		let self = this;
		document.onkeydown = function(evt) {
			evt = evt || window.event;
			var isEscape = false;
			if ("key" in evt) {
				isEscape = (evt.key === "Escape" || evt.key === "Esc");
			} else {
				isEscape = (evt.keyCode === 27);
			}
			if (isEscape) {
				self.closeItem();
			}
		};
	}

	componentDidUpdate() {

		let elemDesc = document.querySelector('.detail__desc');
		//let container = document.querySelector( '.detail');
		let items = document.querySelectorAll( '.fx-done');

		// if opening content
		if( this.props.data !== null ){

			elemDesc.classList.remove('fx', 'fx-fade');

			// fx
			let items = document.querySelectorAll( '.fx');
			let cnt = 0;
			for( let item of items ){
				cnt ++;
				setTimeout(()=>{
					item.classList.add('fx-done');
				}, cnt * 137);
			}
			
			setTimeout( ()=>{
				// cursor
				updateStyle( "detail" );
				// title anim
				this.texturize();
				// text anim
				this.texturizeHtml();
				// desc opacity
				elemDesc.style.opacity = '1';
			}, 770 );
			
		}
		// if closing content
		else{

			for( let item of items ){
				item.classList.remove('fx-done');
			}
			// clear media
			let elemMedia = document.querySelector('.detail__media');
			elemMedia.classList.remove('active');
			// clear desc
			let elemDesc = document.querySelector('.detail__desc');
			elemDesc.classList.add('fx', 'fx-fade');
			elemDesc.style.height = '0';
			// cursor
			updateStyle( "work" );
		}
		
		updateEvents();
	}

	texturize() {
		let element = document.querySelector('.detail__title');
		let elemChars = element.dataset.texturize.split('');

		let cnt = 0;
		for( let c in elemChars ){
			cnt ++;
			setTimeout(()=>{
				let spanChar = document.createElement('span');
				spanChar.classList.add('fx','fx-char');
				if( elemChars[c] === ' ' ){
					spanChar.classList.add('space');
				}
				spanChar.innerHTML = elemChars[c];
				element.appendChild(spanChar);

				setTimeout(()=>{
					spanChar.classList.add('fx-done');
				},73);

			}, cnt * 27);
		}
		
	}

	texturizeHtml(){

		let element = document.querySelector('.detail__desc');
		let elementInner = document.querySelector('.detail__desc > p');
		let elemStyle = getComputedStyle(element);
		let lineH = parseInt( elemStyle.getPropertyValue('line-height') );
		let textH = elementInner.clientHeight;
		let txtLines = Math.ceil( textH / lineH );
		let safetyHeightAdd = 20;

		for( let l = 0; l <= txtLines; l++ ){
			setTimeout(()=>{
				element.style.height = (l * lineH ) + "px";

				// show media on last line
				if( l === txtLines ){
					element.style.height = parseInt(element.style.height) + safetyHeightAdd + "px";
					setTimeout(this.displayMedia, 73);
				}

			}, l*73);
		}
	}

	displayMedia(){
		let element = document.querySelector('.detail__media');
		element.classList.add('active');
	}

	closeItem(e){
		
		// take five items to animate on close
		let items = document.querySelectorAll( '.detail .fx-done:not(span)');
		let itemsSliced = Array.prototype.slice.call(items).slice(0,6);

		let cnt = 0;
		for( let item of itemsSliced ){
			cnt++;
			setTimeout(()=>{
				item.classList.remove('fx-done');
			}, cnt * 173);
		}

		// clear desc
		let elemDesc = document.querySelector('.detail__desc');
		elemDesc.classList.add('fx', 'fx-fade');
		elemDesc.style.height = '0';

		// close
		let elemClose = document.querySelector( '.detail-close');
		elemClose.classList.remove('mount');

		// clear media
		let elemMedia = document.querySelector('.detail__media');
		elemMedia.classList.remove('active');

		// reset
		setTimeout(() =>{
			
			// clear title
			let elemTitle = document.querySelector('.detail__title');
			elemTitle.innerHTML = '';

			// remove data
			this.props.detailHandler( null );

			// cursor
			updateStyle( "work" );

		}, itemsSliced.length * 173);
	}


	render() {

		let data = {...this.props.data};

		let dataClass = '';
		dataClass = ( this.props.data !== null ) ? "mount" : "";

		let images = '';
		if( data.hasOwnProperty('media') ){
			images = data.media.map( (image,index) => {
				// video
				if( image.data.lastIndexOf('mp4') > 0 || image.data.lastIndexOf('webm') > 0 ){
					return <video className="fx" key={index} src={`cms/uploads/item/${data.id}/${image.data}`} loading="lazy" controls ></video>;
				}
				// audio
				if( image.data.lastIndexOf('wav') > 0 || image.data.lastIndexOf('mp3') > 0 ){
					return <audio className="fx" key={index} src={`cms/uploads/item/${data.id}/${image.data}`} loading="lazy" controls ></audio>;
				}
				// doc, pdf
				if( image.data.lastIndexOf('pdf') > 0 || image.data.lastIndexOf('doc') > 0 ){
					return <a className="fx btn" key={index} href={`cms/uploads/item/${data.id}/${image.data}`} target="_blank" rel="noopener noreferrer" >Download {`${image.data}`}</a>;
				}
				// img
				else{
					//return <img className="fx" key={index} src={`cms/uploads/item/${data.id}/${image.data}`} loading="lazy" alt={`${data.title} : ${index}`} />
					return <Img 	key={index}
											index={index}
											src={`https://www.borisvidosevic.com/cms/uploads/item/${data.id}/${image.data}`}
											alt={image.data}
											caption={image.data} />
				}
				
			});
		}

		return (
			<React.Fragment>
				<button 	className={`detail-close btn ${dataClass} `} onClick={this.closeItem}>Close</button>
				<div 		className={`detail-underlay ${dataClass} `} ></div>
				<div 		className={`detail ${dataClass} `} >
					<div className="wrapper">
						<h2 		className="detail__title fx fx-slide" data-texturize={data.title}>&nbsp;</h2>
						<div 		className="detail__desc " style={{height: 0}}>
							<p dangerouslySetInnerHTML={{__html:data.description}}></p>
						</div>
						<div 		className="detail__media ">{images}</div>
					</div>
				</div>
			</React.Fragment>
		)
	}

}

export default Detail;