import React from 'react';
import { parseDate } from '../helpers/General';

class Item extends React.Component {

	constructor(props){
		super(props);

		this.state = {
			slug: ''
		};

		this.detailHandle = this.detailHandle.bind(this);
	}

	detailHandle(){
		this.props.detailHandler( this.props.data );
	}

	render() {

		const total = this.props.count;

		const readMore = this.props.hasOwnProperty( 'detailHandler' );
		
		return (
			<React.Fragment>
				<div className="item" data-slug={this.props.data.slug} >
					<div className="wrapper">

						<div className="item__details">
							{ this.props.data.index && <span className="item__count">{this.props.data.index}/{total}</span> }
							<span className="item__title" >{this.props.data.title}</span>
							<img className="item__image" src={this.props.data.images} alt="" />
							<div className="clearfix">
								{ this.props.data.date_content && <time className="item__date">{ parseDate(this.props.data.date_content) }</time> }
								{ this.props.data.subtitle && <span className="item__subtitle">{this.props.data.subtitle}</span> }
								{ readMore && <button className="item__cta btn" onClick={this.detailHandle}>Read More</button> }
							</div>
							{ this.props.data.description && <div className="item__description" dangerouslySetInnerHTML={{__html:this.props.data.description}}></div> }
						</div>

					</div>
				</div>
			</React.Fragment>
		)
	}

}

export default Item;