import React from 'react';
import { parseDate, parseCode } from '../helpers/General';
// import Carousel from 'react-images';
import { Facebook, Twitter, Google, Linkedin, Mail } from 'react-social-sharing'
import Img from './../components/Img';

class BlogArticle extends React.Component {

	constructor(props){
		super(props);

		this.state = {
			slug: ''
		};
	}

	render() {

		const total = this.props.data.length;
		const images = (this.props.data.media.length > 0) 
						? this.props.data.media.map( (image,index) => {
							//return {src: `cms/uploads/${this.props.contentType}/${this.props.data.id}/${image.data}`}
							return <Img 	key={index}
											index={this.props.index}
											src={`https://www.borisvidosevic.com/cms/uploads/${this.props.contentType}/${this.props.data.id}/${image.data}`}
											alt={this.props.data.title}
											caption={this.props.data.title} />
							})
						: [];

		const socialUrl = window.location.href;
		
		return (
			<React.Fragment>

				<div className="blog-article" data-slug={this.props.data.slug} >
					<div className="wrapper">

						<div className="blog-article__details">
							{ this.props.data.index && <span className="blog-article__count">{this.props.data.index}/{total}</span> }
							<div className="clearfix">
								<div className="blog-article__info">
									<span className="blog-article__title" >{this.props.data.title}</span>
									{ this.props.data.date_content && <time className="blog-article__date">{parseDate(this.props.data.date_content, true)}</time> }
									{ this.props.data.subtitle && <span className="blog-article__desc">{this.props.data.subtitle}</span> }
									{ this.props.data.description && <p className="blog-article__text" dangerouslySetInnerHTML={{__html:parseCode(this.props.data.description)}}></p> }
								</div>

								<div className="blog-article__social" >
									<Facebook  small link={socialUrl} />
									<Twitter  small  link={socialUrl} />
									<Linkedin  small  link={socialUrl} />
									<Google  small  link={socialUrl} />
									<Mail  small  link={socialUrl} />
								</div>
							</div>
						</div>

						<div className="blog-article__media">
							{ images.length > 0 && images }
						</div>

					</div>
				</div>

			</React.Fragment>
		)
	}

}

export default BlogArticle;