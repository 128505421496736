import React from 'react';
import { api } from '../data/Api';
import Article from './../components/Article';
import Empty from './../components/Empty';
//import { checkScroller } from '../containers/App';
import { toggleItems, setLoader, setAnimation, toTop, toggleMobileMenu } from '../helpers/General';
import { updateStyle, updateEvents } from './../helpers/Cursor';
import 'whatwg-fetch'; 

class Projects extends React.Component{

	constructor(props){
		super(props);

		this.state = {
			data: null
		}
	}

	componentDidMount() {

		toggleMobileMenu();

		setLoader(true);
		setAnimation(true);
		fetch( api.routes.projects )
			.then(response => response.json())
			.then(data => {
				this.setState({ data })
				toggleItems('.projects');
				
				updateEvents();
				updateStyle( "projects" );
				setLoader(false);
				setAnimation(false);

				toTop();
			});
	}

	render(){

		let projects = [];

		if( this.state.data != null ){
			let items = this.state.data[0].category.items;
			if( items.length > 0 ){
				projects = items.map( (item, index) => {
					return <Article 	key={item.slug} 
										index={index}
										data={item} 
										contentType="item"
										detailHandler={this.props.detailHandler}
							></Article>
				})
			}else{
				projects = <Empty message={`Gathering projects, please come by later.`} />
			}
		}

		return (
			<div className="projects">
				<div className="wrapper">

					<div className="articles clearfix">
						{ projects }
					</div>
					
				</div>
			</div>
		)
	}
}

export default Projects;