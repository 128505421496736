export function toggleItems(container) {

	let items = document.querySelectorAll( container + ' .item');
	let cnt = 0;
	for( let item of items ){
		cnt ++;
		setTimeout((e)=>{
			item.classList.add('active');
		}, cnt * 137)
	}
}

export function chunk(array, size) {
	if (!array) return [];
	const firstChunk = array.slice(0, size); // create the first chunk of the given array
	if (!firstChunk.length) {
	  return array; // this is the base case to terminal the recursive
	}
	return [firstChunk].concat(chunk(array.slice(size, array.length), size)); 
}

export function toggleMobileMenu(){
	let elem = document.querySelector('.menu');
	elem.classList.toggle('active');
}

export function setLoader(state){
	let loader = document.querySelector('.loader');
	if( loader !== null ){
		let intervalState = (state) ? 1 : 1000;
		if(state){
			document.addEventListener('click', mouseHandler);
			document.addEventListener('mousemove', mouseHandler);
		}
		setTimeout(e=>{
			if( !state ){
				document.removeEventListener('mousemove', mouseHandler);
				document.removeEventListener('click', mouseHandler);
				setTimeout(()=>{
					loader.style.left = "-100px";
					loader.style.top = "-100px";
				},500);
			}
			loader.dataset.loading = state.toString();
		}, intervalState);
	}
}

export function mouseHandler(e) {
	let loader = document.querySelector('.loader');
    e = e || window.event;

    var pageX = e.pageX;
    var pageY = e.pageY;

    // IE 8
    if (pageX === undefined) {
        pageX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        pageY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
	}
	pageX-= 25;
	pageY-= 25;
	if( loader !== undefined ){
		loader.style.left = pageX + "px";
		loader.style.top = pageY + "px";
	}
}

export function parseDate(date, full){
	let arrDate = date.split('-');
	return ( full ) ? arrDate[0] + ' / ' + arrDate[1] + ' / ' + arrDate[2] : arrDate[2];
}

export function parseCode(string){
	return string
		.replaceAll('<oembed url=', '<iframe style="width:100%; height: 400px;" frameborder="0" src=')
		.replaceAll('</oembed>', '</iframe>')
		.replaceAll('|-code|','<pre>')
		.replaceAll('|code-|','</pre>');
}

export function setAnimation(state){
	let body = document.querySelector('body');
	if( state ){
		body.dataset.animation = state.toString();
	}else{
		setTimeout(e=>{
			body.dataset.animation = state.toString();
		},1000);
	}
}

export function toTop(){
	window.scrollTo(0,0);
}

export default toggleItems;