import React from 'react';
import Item from './../components/Item';
import { api } from '../data/Api';
//import { checkScroller } from '../containers/App';
import { toggleItems, setLoader, setAnimation, toTop, toggleMobileMenu } from '../helpers/General';
import { updateEvents, updateStyle } from './../helpers/Cursor';
import Columnizer from '../components/Columnizer';
import 'whatwg-fetch'; 
class Experience extends React.Component{

	constructor(props){
		super(props);

		this.state = {
			data: null
		}
	}

	componentDidMount() {

		toggleMobileMenu();

		setLoader(true);
		fetch( api.routes.experience )
			.then(response => response.json())
			.then(data => {
				setAnimation(false);
				this.setState({ data });
				toggleItems('.experience');
				
				updateEvents();
				updateStyle( "experience" );
				setLoader(false);

				toTop();
			});
	}

	render(){

		let experiences = [];

		if( this.state.data != null ){
			let items = this.state.data[0].category.items;
			experiences = items.map( (item,index) => {
				return <Item 	key={index} 
								data={item} 
								count={items.length} 
								/*detailHandler={this.props.detailHandler} */
						></Item>
			})
		}
		

		return (
			<div className="experience">
				<div className="wrapper">

					<div className="items clearfix">
						<Columnizer columns="3" items={experiences} />
					</div>
					
				</div>
			</div>
		)
	}
}

export default Experience;