import React from 'react';
import { parseDate } from '../helpers/General';
//import Carousel from 'react-images';
import Img from './../components/Img';
class Article extends React.Component {

	constructor(props){
		super(props);

		this.state = {
			slug: ''
		};

		this.detailHandle = this.detailHandle.bind(this);
	}

	detailHandle(){
		this.props.detailHandler( this.props.data );
	}

	render() {

		let custom_elements;
		if( this.props.data.data !== "" ){
			let custom_data = JSON.parse(this.props.data.data);

			custom_elements = Object.keys(custom_data).map( (key) => {
				if( custom_data[key] !== '' ){
					if( key === 'date' ){
						return <span key={key}><small>{key}:</small> {parseDate( custom_data[key] )}</span>
					}else{
						return <span key={key}><small>{key}:</small> { custom_data[key]}</span>
					}
				}
				return false;
			});
		}
		

		const total = this.props.data.length;
		const images = (this.props.data.media.length > 0) 
						? this.props.data.media.slice(0,1).map( (image,index) => {
							//return {src: `cms/uploads/${this.props.contentType}/${this.props.data.id}/${image.data}`}
							return <Img 	key={index}
											index={this.props.index}
											src={`https://www.borisvidosevic.com/cms/uploads/${this.props.contentType}/${this.props.data.id}/${image.data}`}
											alt={this.props.data.title}
											caption={this.props.data.title} />
							})
						: [];

		const readMore = this.props.hasOwnProperty( 'detailHandler' );
		
		return (
			<React.Fragment>
				<div className="article" data-slug={this.props.data.slug} >
					<div className="wrapper">

						<div className="article__details">
							{ this.props.data.index && <span className="article__count">{this.props.data.index}/{total}</span> }
							<div className="clearfix">
								<div className="article__media">
									{images}
									{/* images.length > 0 && <Carousel views={images} adaptiveHeight={true} /> */}
								</div>
								<div className="article__info">
									<span className="article__title" >{this.props.data.title}</span>
									<span className="article__custom">{ custom_elements }</span>
									{ this.props.data.subtitle && <span className="article__text">{this.props.data.subtitle}</span> }
									{ readMore && <button className="article__cta btn" onClick={this.detailHandle}>Read More</button> }
								</div>
							</div>
						</div>

					</div>

				</div>

			</React.Fragment>
		)
	}

}

export default Article;