import React from 'react';

class Empty extends React.Component {

	render() {
		
		return (
			<React.Fragment>
				<div className="empty" >
					<div className="wrapper">
						{ this.props.message }
					</div>
				</div>

			</React.Fragment>
		)
	}

}

export default Empty;