import React from 'react';
import { NavLink } from "react-router-dom";
import history from './../containers/History';

import { api } from '../data/Api';
import { updateStyle, updateEvents } from './../helpers/Cursor';
import 'whatwg-fetch'; 

class Menu extends React.Component{
	
	constructor(props){
		super(props);

		this.state = {
			data: null,
			page: ''
		}

		this.animateMenu = this.animateMenu.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
	}

	componentDidMount(){

		fetch( api.routes.menu )
			.then(response => response.json())
			.then(data => {
				this.setState({ data });
				document.querySelector('.menu-open').classList.remove('hide');
			});

		this.getDataPage();
		this.toggleMenu();
	}
	
	componentDidUpdate(){
		this.getDataPage();
	}

	getDataPage( pathname ){
		let dataPath = history.location.pathname.replace('/', '') || 'home';
		document.querySelector('#content').setAttribute('data-page', dataPath );
		document.querySelector('body').setAttribute('data-page', dataPath );
		let menuTitle = ( dataPath === 'home' ) ? 'hello' : dataPath;
		document.querySelector('.menu-open').innerHTML = menuTitle;
	}

	animateMenu(){
		let menuElems = document.querySelectorAll('.menu li');
		[...menuElems].forEach( (elem,index) => {
			setTimeout( e => {
				elem.classList.add('done');
			}, index * 90 );
		});
	}

	toggleMenu(){
		let elem = document.querySelector('.menu');
		elem.classList.toggle('active');

		if( elem.classList.contains('active') ){
			updateStyle( "menu" );
			updateEvents();
			setTimeout( this.animateMenu, 500 );
		}else{
			updateStyle( document.querySelector('body').dataset.page );
		}

		// remove fx classes from menu elems
		let menuElems = document.querySelectorAll('.menu li');
		[...menuElems].forEach( (elem,index) => {
			elem.classList.remove('done');
		});
	}

	render(){

		let categories = [];
		let toggleClass = ( this.state.open ) ? 'active' : '';
		
		if( this.state.data !== null ){
			categories = this.state.data.map( (category,index) => {
				let categoryData = category.category;
				return <li key={index}  className="menu__api-link">
					<NavLink activeClassName='active' to={`${process.env.PUBLIC_URL}/${categoryData.slug}`} >
						{categoryData.title}
					</NavLink>
				</li>
			});
		}

		return (

			<React.Fragment>
				<button className="btn menu-open hide" onClick={ this.toggleMenu }>hello</button>
				<div className={`menu ${toggleClass}`}>
					<button className="btn menu-close" onClick={ this.toggleMenu }>Close</button>
					<ul className="wrapper">
						<li><NavLink activeClassName="active" exact={true} to={`${process.env.PUBLIC_URL}/`} >hello</NavLink></li>
						{ categories }
					</ul>
					<p className="menu-contact">
						<a href="mailto:boris.vidosevic@gmail.com">em</a>
						<a href="http://www.twitter.com/friglob" target="_blank" rel="noopener noreferrer">tw</a>
						<a href="http://www.behance.com/friglob" target="_blank" rel="noopener noreferrer">be</a>
						<a href="http://www.instagram.com/friglob" target="_blank" rel="noopener noreferrer">ig</a>
						{/*<a href="http://www.soundcloud.com/friglob" target="_blank" rel="noopener noreferrer">sc</a>*/}
					</p>
					<p className="menu-info">
						have a project? <a href="mailto:boris.vidosevic@gmail.com">get in touch</a>&nbsp;&nbsp;&nbsp;
						cover videos by <a href="https://www.pexels.com/@stef-1955763" target="_blank" rel="noopener noreferrer">stef</a>
					</p>
				</div>
			</React.Fragment>
		)
	}
	
}

export default Menu