import React from 'react';
import { updateStyle, updateEvents } from './../helpers/Cursor';
import { setLoader, toggleMobileMenu } from '../helpers/General';
import 'whatwg-fetch'; 

//const mediaChangeInterval = 7300;

const text = [
	{	
		add:		"i make",
		prefix: 	"",
		text: 		"things",
		suffix: 	"",
		video: 		"1.mp4",
		image: 		"1.png"
	},
	{
		add:		"and audiovisual",
		prefix: 	"",
		text: 		"solutions", 	
		suffix: 	"",
		video: 		"2.mp4",
		image:		"ink2.png",
	},
	{
		add:		"in analog and",
		prefix: 	"",
		text: 		"digital",
		suffix: 	"",
		video: 		"3.mp4",
		image:		"ink1.png"
	}
];

const slidesInterval = 0;
const fadeOutTransit = 5;
const fadeInTransit = 95;

const showAudio = false;
const audio_file_1 = "21.mp3";
const audio_file_2 = "22.mp3";
const audio_file_3 = "23.mp3";

class Home extends React.Component{

	
	homeInterval = 0;

	constructor(props) {
		super(props);
		this.state = {
			key: -1,
			muted: true,
			muted_slices: [true,true,true],
			isMobile: false
		};

		this.renderText = this.renderText.bind(this);
		this.toggleSound = this.toggleSound.bind(this);
		this.toggleSoundSet = this.toggleSoundSet.bind(this);
	}


	componentDidMount() {

		toggleMobileMenu();

		updateEvents();
		updateStyle( "home" );

		//checkScroller(1);
		setLoader(false);

		// start render
		setTimeout(this.renderText, 500);
		if( slidesInterval > 0 ){
			setInterval(this.renderText, slidesInterval);
		}

		// check mobile
		//this.setState({isMobile: window.innerWidth < 992 ? true : false});
		

		let homeElem = document.querySelector('.home');
		let videoElem = document.querySelector('.home__video');
		let videoBar = document.querySelector('.home__duration');
		videoElem.addEventListener("timeupdate", (e)=>{
			// get percantage
			let percentage = parseInt( (videoElem.currentTime/videoElem.duration)*100);

			// transit classes : start
			if( percentage >= fadeInTransit ){
				homeElem.classList.add('transit');
			}
			// transit classes : end
			else if(percentage > fadeOutTransit ){
				homeElem.classList.remove('transit');
			}

			// keep bar to zero on start
			let percentageBar = /*( percentage <= 13 ) ? 0 : */percentage;
			videoBar.style.width = percentageBar + "%";

		}, true);
	}

	componentWillUnmount() {
		clearInterval( this.homeInterval );
	}

	renderText(){

		let contentContainer = document.querySelector('.js-home-content');
		if( contentContainer === null ){
			return false;
		}
		contentContainer.innerHTML = '';

		// set active key
		//let key = text.length * Math.random() | 0; 							// random
		let key = (this.state.key < text.length-1 ) ? this.state.key+1 : 0; 	// increment
		let textKey = text[key];
		this.setState({ key: key });
		//console.log(key, textKey);
		
		// text
		let arrText = [...textKey.text ];
		let cnt = 0;
		for( let char in arrText ){
			let spanElem = document.createElement('span');
			spanElem.classList.add('home__letter');
			let spanChar = ( arrText[char].trim() ) || '&nbsp;';
			spanElem.innerHTML = spanChar;
			if( spanChar === '&nbsp;' ){
				spanElem.classList.add('home__letter--break');
			}
			contentContainer.appendChild(spanElem);
			cnt ++;
			setTimeout((e)=>{
				spanElem.classList.add('active');
			}, cnt * 43);
		}

		// video
		let videoSrc = `/assets/video/${text[this.state.key].video}`;
		let videoElem = document.querySelector('.home__video');
		videoElem.setAttribute('src', videoSrc );
		videoElem.addEventListener("ended", this.renderText);

		// image
		/*
		let imageSrc = `/assets/image/${text[this.state.key].image}`;
		let imageElem = document.querySelector('.home__image');
		imageElem.setAttribute('src', imageSrc );
		*/

		// add
		let addElem = document.querySelector('.home__subcontent');
		addElem.innerHTML = textKey.add;
	}

	toggleSoundSet(){
		if( this.state.muted === false ){
			this.setState({
				muted_slices: this.getRandSet()
			});
			//document.querySelector('.home__audio--1').play();
		}
	}

	toggleSound(){
		this.setState({
			muted: !this.state.muted,
			muted_slices: (this.state.muted) ? this.getRandSet() : [true,true,true]
		});
	}

	getRandSet(){

		return [false,true,true];
		/*
		return [
			(Math.random() >= 0.5),
			(Math.random() >= 0.5),
			(Math.random() >= 0.5)
		];
		*/
		
	}

	render(){

		let prefix = '';
		let suffix = '';
		if( text[this.state.key] !== undefined ){
			prefix = text[this.state.key].prefix;
			suffix = text[this.state.key].suffix;
		}

		let mute_class = (this.state.muted) ? "muted" : "";
		let playing_status_elems = this.state.muted_slices.map( 
			(muted,index) => {
				return ( !muted && !this.state.muted )
					? <span key={index} className={`item--${index}`} ></span> 
					: <span key={index} className={`item--${index} item--disabled`} ></span> 
				;
			});

		return (

			<div className="home">
			<video className="home__video" autoPlay muted></video>
				{ showAudio && !this.state.isMobile &&
					<React.Fragment>
						<div className={`home__mute-status ${mute_class}`} >{playing_status_elems}</div>
						<button className={`home__mute btn ${mute_class}`} onClick={ this.toggleSound } ><span>sound</span></button>
						<audio className="home__audio home__audio--1" src={`/assets/audio/${audio_file_1}`} autoPlay loop muted={this.state.muted_slices[0]} ></audio>
						<audio className="home__audio home__audio--2" src={`/assets/audio/${audio_file_2}`} autoPlay loop muted={this.state.muted_slices[1]} ></audio>
						<audio className="home__audio home__audio--3" src={`/assets/audio/${audio_file_3}`} autoPlay loop muted={this.state.muted_slices[2]} ></audio>
					</React.Fragment>
				}
				{/*<img className="home__image" alt="bg"/>*/}
				<div className="home__overlay"></div>
				<div className="home__duration"></div>
				{/*<div className="home__transit"></div>*/}
				
				<div className="wrapper">
					<p className="home__content">
						<span className="home__prefix">{prefix}
							<span className="home__subcontent"></span>
							<span className="home__text js-home-content"></span>
						</span>
						<span className="home__suffix">{suffix}</span>
					</p>
				</div>
				
			</div>

		)
	}
}

export default Home;